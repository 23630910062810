import React, { useEffect } from "react";
import Responsive from "./Components/Responsive";
import { useRecoilState } from "recoil";
import { ConfigState } from "./Atoms/configs.atom";
import { Button, Input } from "rsuite";
import { APi } from "./Api";
import { ENDPOINTS } from "./Api/enpoints";
import { userState } from "./user.atom";

export default function Configs() {
  const [conf, setconf] = useRecoilState(ConfigState);
  const [user, setuser] = useRecoilState(userState);
  useEffect(() => {
    console.log(user);
  }, []);
  const save = () => {
    if (conf.id) {
      APi.createAPIEndpoint(ENDPOINTS.Config)
        .update(conf.id, conf)
        .then((res) => alert("bien modifié"));
    } else {
      let userId = user.id;
      APi.createAPIEndpoint(ENDPOINTS.Config)
        .create({ ...conf, userId })
        .then((res) => alert("bien enregistré"));
    }
  };
  return (
    <div>
      <Responsive l={6} xl={6} className="p-10">
        <label>Nom d'utilisateur Nusuk : </label>
        <Input
          value={conf.login}
          onChange={(login) => setconf((prev) => ({ ...prev, login }))}
        />
      </Responsive>
      <Responsive l={6} xl={6} className="p-10">
        <label>Mot de passe de Nusuk: </label>

        <Input
          value={conf.password}
          onChange={(password) => setconf((prev) => ({ ...prev, password }))}
        />
      </Responsive>
      <Button appearance="primary" onClick={save}>
        Enregistrer
      </Button>
    </div>
  );
}
