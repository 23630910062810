export class PilgrimModel {
  status; // الصفة;
  cin; // ب.ت.و;
  firsNameAr; // إسم المترشح
  lastNameAr; //لقب المترشح
  fatherName; // "إسم الأب
  grandfatherName; // إسم الجد
  motherFirstName; //إسم ألأم
  motherLastName; //لقب ألأم
  phoneNumber; // رقم الهاتف
  documentNumber; /// رقم الملف
  governate; ///الولاية
  city; // المعتمدية
  isPaid; //أتم عملية الدفع
  confirmationDate; //تاريخ تأكيد المطلب"
  sequenceNumber; //رقم الوصل
  passportNumber; //جواز السفر
  documentConfirmation; //تأكيد الملف
  flightNumber; //رقم الرحل
  firsName; //اللقب في جواز السفر
  lastName; //الأسم في جواز السفر
  birthDate; //تاريخ الولادة
  gender; //الجنس
  mrz;
  email;
  passportDeliveryDate;
}
