import { Button, Checkbox } from "rsuite";
import Grid from "../Components/Grid";
import { useEffect, useState } from "react";
import { APi } from "../Api";
import { ENDPOINTS } from "../Api/enpoints";
import axios from "axios";

const Visas = ({ groupId, group, setgroup }) => {
  const [pilgrims, setpilgrims] = useState([]);
  const [checkeds, setcheckeds] = useState([]);

  const [stop, setstop] = useState(false);
  const [index, setindex] = useState(0);
  const [loading, setloading] = useState(false);
  useEffect(() => {
    localStorage.setItem("stop1", true);
    setcheckeds([]);
  }, []);
  const send_recursive = async (i, els) => {
    setindex(els[i].id);

    let _st = localStorage.getItem("stop1");
    console.log(_st);
    if (_st == "false") {
      let _res = await axios.post(
        "http://localhost:8733/PrintVisaHajj",
        els[i]
      );
      let _el = { ...els[i] };
      if (_res.status == 200) {
        _el.errorMessage = _res.data;
      } else {
        _el.errorMessage = "Error";
      }
      setpilgrims((prev) => {
        let da = [...prev];
        let j = da.findIndex((ell) => ell.id == els[i].id);
        da[j] = _el;
        return da;
      });

      APi.createAPIEndpoint(ENDPOINTS.Pilgrim)
        .update(_el.id, _el)
        .then((res) => {
          debugger;
          if (i < els.length - 1) {
            send_recursive(i + 1, els);
          } else setloading(false);
        })
        .catch((e) => {
          console.log("index: ", i);
          if (i < els.length - 1) {
            send_recursive(i + 1, els);
          } else setloading(false);
        });
    }
  };

  const fetch = () => {
    APi.createAPIEndpoint(ENDPOINTS.Pilgrim, { page: 1, take: 11000, groupId })
      .customGet()
      .then((res) => setpilgrims(res.data.data));
  };
  useEffect(() => {
    fetch();
    console.log(group);
  }, [groupId]);
  const send = async () => {
    localStorage.setItem("stop1", false);
    setloading(true);
    console.log(pilgrims.filter((p) => checkeds.find((_p) => _p == p.id)));
    console.log(checkeds);
    let els = pilgrims
      .filter((p1) => checkeds.find((_p) => _p == p1.id))
      .map((p) => ({
        ...p,
        code: p.documentConfirmation,
        country: "TUN",
        id: p.id,
      }));
    await send_recursive(0, els);
  };
  const columns = [
    {
      value: "firsNameAr",
      //value2: "firsName",
      value2: "lastNameAr",
      value3: "id",
      //value4: "lastName",
      name: " المترشح",
      render: (v, v2, id) => (
        <b style={{ color: "green", display: "flex", alignItems: "center" }}>
          <Checkbox
            onChange={(v) => {
              if (checkeds.find((el) => el == id))
                setcheckeds((prev) => prev.filter((l) => l != id));
              else setcheckeds((prev) => [...prev, id]);
            }}
            checked={checkeds.find((el) => el == id) != null}
          ></Checkbox>
          <span> {v + " " + v2} </span>
          {/* <i style={{ color: "#444" }}>{v2 + " " }</i> */}
        </b>
      ),
    },

    {
      value: "documentNumber",
      name: "رقم الملف",
      render: (v) => <b style={{ color: "#232323" }}>{v}</b>,
    },

    {
      value: "sequenceNumber",
      name: "رقم الوصل",
      render: (v) => <i style={{ color: "#232323" }}>{v}</i>,
    },
    {
      value: "passportNumber",
      name: "Passport",
      render: (v) => <b style={{ color: "green" }}>{v}</b>,
    },
    {
      value: "errorMessage",

      name: "Resultat",
      render: (v) => (
        <span
          style={{
            color: v == "Error" ? "red" : v == "Success" ? "green" : "",
          }}
        >
          {v}
        </span>
      ),
    },
  ];
  return (
    <div>
      <div style={{ textAlign: "right", padding: "10px" }}>
        <Button color="" onClick={setgroup}>
          retour
        </Button>{" "}
        <Button color="orange" onClick={send}>
          visas
        </Button>{" "}
        <Button
          color="red"
          onClick={() => {
            setstop(true);
            localStorage.setItem("stop1", true);
            setloading(false);
          }}
        >
          arréter
        </Button>
      </div>
      <div
        onClick={(e) =>
          setcheckeds((prev) =>
            prev.length ? [] : pilgrims.map((el) => el.id)
          )
        }
        style={{
          display: "flex",
          alignItems: "center",

          padding: "8px",
          borderRadius: "4px",
          background: "#fff",
        }}
      >
        <Checkbox checked={checkeds.length == pilgrims.length}></Checkbox>{" "}
        Sélectionner Tout
      </div>
      {loading && (
        <div
          style={{
            display: "flex ",
            alignItems: "center",
            border: "1px solid #eee",
            padding: "10px",
            background: "#fff",
            maxWidth: "800px",
            margin: "0 auto",
            marginBottom: "10px",
            justifyContent: "space-between",
          }}
        >
          <div>
            {" "}
            En cours :
            <b style={{ color: "green" }}>
              {pilgrims.find((p) => p.id == index).firsName +
                "  " +
                pilgrims.find((p) => p.id == index).lastName}{" "}
              <br></br>
              <i style={{ color: "#444" }}>
                {pilgrims.find((p) => p.id == index).firsNameAr +
                  "  " +
                  pilgrims.find((p) => p.id == index).lastNameAr}
              </i>
            </b>
          </div>
          <img alt="...loading" src="/svg-loaders/spinning-circles.svg"></img>
        </div>
      )}
      <Grid columns={columns} rows={pilgrims} />
    </div>
  );
};

export default Visas;
