import "./App.scss";
import { useRecoilState } from "recoil";
import { userState } from "./user.atom";
import PeoplesIcon from "@rsuite/icons/Peoples";
import SingleSourceIcon from "@rsuite/icons/SingleSource";
import {
  Button,
  ButtonToolbar,
  Content,
  FlexboxGrid,
  Form,
  IconButton,
  Input,
  InputGroup,
  Message,
  Panel,
} from "rsuite";
import { useEffect, useState } from "react";
import Pilgrims from "./Pilgrims";
import Groups from "./Groups";
import { AuthService } from "./Api/auth.service";
import { Exit, Setting } from "@rsuite/icons";
import { APi } from "./Api";
import { ConfigState } from "./Atoms/configs.atom";
import Configs from "./Configs";
import { ENDPOINTS } from "./Api/enpoints";
function App() {
  const [user, setuser] = useRecoilState(userState);
  const [model, setmodel] = useState({ username: "", password: "" });
  const [error, seterror] = useState("");
  const [_route, set_route] = useState("pilgrims");
  const [conf, setconf] = useRecoilState(ConfigState);

  function authenticate() {
    if (model.username && model.password) {
      AuthService()
        .login(model)
        .then((res) => {
          if (res.data.success) {
            localStorage.setItem("auth", JSON.stringify(res.data));
            setuser(res.data);
            seterror("");
          } else seterror(res.data.message);
        })
        .catch((er) => seterror(er.Message));
    }
  }
  useEffect(() => {
    let _user = localStorage.getItem("auth")
      ? JSON.parse(localStorage.getItem("auth"))
      : "";
    setuser(_user);
  }, []);
  useEffect(() => {
    if (user)
      APi.createAPIEndpoint(ENDPOINTS.Config)
        .fetchById(user.id)
        .then((res) => setconf(res.data));
  }, [user]);
  useEffect(() => {
    localStorage.setItem("stop", true);
  }, [_route]);
  return (
    <div className="hole-container">
      <div className="page-top">
        <img src="/logo.png" className="app-logo" alt="logo" />
        <img src="/logo-small.png" className="app-logo-mobile" alt="logo" />
        {user && (
          <ButtonToolbar>
            <IconButton
              title="Configuration"
              icon={<Setting />}
              onClick={() => set_route("configs")}
              appearance={_route == "configs" ? "primary" : ""}
              className="p-5"
            />
            <IconButton
              icon={<PeoplesIcon />}
              onClick={() => set_route("pilgrims")}
              appearance={_route == "pilgrims" ? "primary" : ""}
              className="p-5"
            >
              Pelerins
            </IconButton>
            <IconButton
              icon={<SingleSourceIcon />}
              onClick={() => set_route("groups")}
              appearance={_route == "groups" ? "primary" : ""}
              className="p-5"
            >
              Groupes
            </IconButton>
            <IconButton
              title="Déconnexion"
              icon={<Exit />}
              onClick={() => setuser(null)}
              className="p-5"
            />
          </ButtonToolbar>
        )}
      </div>
      {user ? (
        <div className="page-container">
          <div className="page-container2">
            {_route == "pilgrims" ? (
              <Pilgrims />
            ) : _route == "configs" ? (
              <Configs></Configs>
            ) : (
              <Groups />
            )}
          </div>
        </div>
      ) : (
        <div className="login-container">
          <img src="/logo.png" alt="logo" />
          <InputGroup style={{}}>
            <InputGroup.Addon> @</InputGroup.Addon>
            <Input
              name="username"
              onChange={(username) => {
                setmodel((prev) => {
                  return { ...prev, username };
                });
              }}
            />
          </InputGroup>
          <br></br> <br></br>
          <InputGroup style={{}}>
            <InputGroup.Addon> #</InputGroup.Addon>
            <Input
              name="password"
              type="password"
              onChange={(password) => {
                setmodel((prev) => {
                  return { ...prev, password };
                });
              }}
            />
          </InputGroup>{" "}
          <br></br>
          <br></br>
          <Button appearance="primary" onClick={authenticate}>
            Connexion
          </Button>
        </div>
      )}
    </div>
  );
}

export default App;
