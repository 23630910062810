import { DatePicker, Input, Message, SelectPicker } from "rsuite";
import Responsive from "../Components/Responsive";
import { PilgrimModel } from "../PilgrimModel";

function AddEdit({
  _setmodel,
  error,
  model = new PilgrimModel(),
  groups = [],
}) {
  return (
    <>
      <Responsive m={6} l={6} xl={6} className="p-10">
        <label>Groupe :</label>
        <SelectPicker
          searchable={false}
          data={[{ label: "Tout", value: 0 }].concat(
            groups.map((el) => ({ value: el.id, label: el.name }))
          )}
          block
          noSearch
          value={model.groupId}
          onSelect={(groupId) => {
            _setmodel((prev) => {
              return { ...prev, groupId };
            });
          }}
        />
      </Responsive>
      <Responsive l={6} xl={6} className="p-10">
        <label>Nom Arabe:</label>
        <Input
          onChange={(lastNameAr) => {
            _setmodel((prev) => {
              return { ...prev, lastNameAr };
            });
          }}
          value={model.lastNameAr}
        />
      </Responsive>
      <Responsive l={6} xl={6} className="p-10">
        <label>Prénom Arabe:</label>
        <Input
          onChange={(firsNameAr) => {
            _setmodel((prev) => {
              return { ...prev, firsNameAr };
            });
          }}
          value={model.firsNameAr}
        />
      </Responsive>
      <Responsive l={6} xl={6} className="p-10">
        <label>Nom :</label>
        <Input
          onChange={(lastName) => {
            _setmodel((prev) => {
              return { ...prev, lastName };
            });
          }}
          value={model.lastName}
        />
      </Responsive>
      <Responsive l={6} xl={6} className="p-10">
        <label>Prénom:</label>
        <Input
          onChange={(firsName) => {
            _setmodel((prev) => {
              return { ...prev, firsName };
            });
          }}
          value={model.firsName}
        />
      </Responsive>
      <Responsive l={6} xl={6} className="p-10">
        <label>Nom Du Pére :</label>
        <Input
          onChange={(fatherName) => {
            _setmodel((prev) => {
              return { ...prev, fatherName };
            });
          }}
          value={model.fatherName}
        />
      </Responsive>
      <Responsive l={6} xl={6} className="p-10">
        <label>Nom Du Grand Pére :</label>
        <Input
          onChange={(grandfatherName) => {
            _setmodel((prev) => {
              return { ...prev, grandfatherName };
            });
          }}
          value={model.grandfatherName}
        />
      </Responsive>
      <Responsive l={6} xl={6} className="p-10">
        <label>CIN:</label>
        <Input
          onChange={(cin) => {
            _setmodel((prev) => {
              return { ...prev, cin };
            });
          }}
          value={model.cin}
        />
      </Responsive>
      <Responsive l={6} xl={6} className="p-10">
        <label>N° (4 chiffres):</label>
        <Input
          onChange={(sequenceNumber) => {
            _setmodel((prev) => {
              return { ...prev, sequenceNumber };
            });
          }}
          value={model.sequenceNumber}
        />
      </Responsive>
      <Responsive l={6} xl={6} className="p-10">
        <label>Date De Naissance:</label>
        <DatePicker
          type="date"
          onChange={(birthDate) => {
            _setmodel((prev) => {
              return { ...prev, birthDate };
            });
          }}
          block
          value={
            // typeof model.birthDate == "string"
            //   ? new Date(model.birthDate.substring(0, 10))
            //   :
            model.birthDate
          }
        />
      </Responsive>
      <Responsive l={6} xl={6} className="p-10">
        <label>Lieu De Naissance:</label>
        <Input
          onChange={(governate) => {
            _setmodel((prev) => {
              return { ...prev, governate };
            });
          }}
          value={model.governate}
        />
      </Responsive>
      <Responsive l={6} xl={6} className="p-10">
        <label>Lieu De Résidance:</label>
        <Input
          onChange={(city) => {
            _setmodel((prev) => {
              return { ...prev, city };
            });
          }}
          value={model.city}
        />
      </Responsive>

      <hr></hr>
      <Responsive l={6} xl={6} className="p-10">
        <label>Passport:</label>
        <Input
          onChange={(passportNumber) => {
            _setmodel((prev) => {
              return { ...prev, passportNumber };
            });
          }}
          value={model.passportNumber}
        />
      </Responsive>
      <Responsive l={6} xl={6} className="p-10">
        <label>Passport D'Issuance :</label>
        <DatePicker
          type="date"
          onChange={(passportDeliveryDate) => {
            _setmodel((prev) => {
              return { ...prev, passportDeliveryDate };
            });
          }}
          block
          value={
            model.passportDeliveryDate
              ? model.passportDeliveryDate.split("-")[0].length == 2
                ? model.passportDeliveryDate.split("-").reverse().join("-")
                : model.passportDeliveryDate
              : ""
          }
        />
      </Responsive>
      <label>MRZ</label>
      <Input
        as="textarea"
        rows={3}
        onChange={(mrz) => {
          _setmodel((prev) => {
            return { ...prev, mrz };
          });
        }}
        value={model.mrz}
      />
      {/* <label>Type de Client</label>

      <SelectPicker
        searchable={false}
        data={clientTypes}
        block
        noSearch
        value={model.clientType}
        onSelect={(clientType) => {
          _setmodel((prev) => {
            return { ...prev, clientType };
          });
        }}
      /> */}
      <br></br>
      {error && (
        <Message showIcon type="error">
          {error}
        </Message>
      )}
    </>
  );
}
// AddEdit.defaultProps = {
//   model: new ClientModel(),
// };
export default AddEdit;
