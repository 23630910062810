import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import {
  Button,
  Icon,
  IconButton,
  Input,
  Modal,
  Pagination,
  SelectPicker,
  Tag,
} from "rsuite";
import Swal from "sweetalert2";
// import { APi } from "../../../Api/";//
import ExportAdd from "../Components/Common/ExportAdd";
import Filter from "../Components/Common/Filter";
import Grid from "../Components/Grid";
import Responsive from "../Components/Responsive";
import AddEdit from "./AddEdit.component";
import { multiDataSet } from "./excel_data";
import { PilgrimModel } from "../PilgrimModel";
import { exportAddAtom } from "../exportAdd.atom";
import { read, utils } from "xlsx";
import { ENDPOINTS } from "../Api/enpoints";
import ReactPaginate from "react-paginate";
import mrzparser from "../Helpers/mrzparser";
import { APi } from "../Api";
import moment from "moment/moment";
import axios from "axios";
import {
  FileDownload,
  FileUpload,
  Image,
  Peoples,
  PeoplesUploaded,
} from "@rsuite/icons";
import ModalBody from "rsuite/lib/Modal/ModalBody";
export default function Pilgrims(props) {
  // STATE
  const [data, setdata] = useState([]);
  const [dataEXcel, setdataEXcel] = useState([]);
  const [totalCount, settotalCount] = useState(0);
  const [passportFile, setpassportFile] = useState(null);

  const [filterModel, setfilterModel] = useState({
    q: "",
    page: 1,
    take: 40,
    groupId: 0,
  });
  const [showCommonInfos, setshowCommonInfos] = useState(false);
  const [loadingFile, setloadingFile] = useState(false);
  const [loadingPassport, setloadingPassport] = useState(false);
  // --- add edit model ---
  const [error, setError] = useState("");
  const [model, setmodel] = useState(new PilgrimModel());
  const [CommonModel, setmCommonModel] = useState({
    id: null,
  });
  const [groups, setgroups] = useState([]);
  // ATOMS
  const [state, setstate] = useRecoilState(exportAddAtom);
  // HELPERS
  const reset = () => {
    setmodel(new PilgrimModel());
    setError("");
  };
  // useEffect(() => {

  // }, []);
  useEffect(() => {
    // let asa = mrzparser.parse(
    //   "P<CANMARTIN<<SARAH<<<<<<<<<<<<<<<<<<<<<<<<<<ZE000509<9CAN8501019F2301147<<<<<<<<<<<<<<08"
    // );
    // console.log(asa);
    fetchGroups();
  }, []);
  // API CALLS
  const fetch = (page = 1) => {
    setstate((prev) => {
      return { ...prev, loading: true };
    });

    APi.createAPIEndpoint(APi.ENDPOINTS.Pilgrim, filterModel)
      .fetchAll()
      .then((res) => {
        setdata(res.data.data);
        setstate((prev) => {
          return { ...prev, loading: false };
        });
        settotalCount(res.data.totalCount);
      })
      .catch((e) => {
        setError(e.Message);
        setstate((prev) => {
          return { ...prev, loading: false };
        });
      });
  };
  const fetchForExcel = async () => {
    let res = await APi.createAPIEndpoint(APi.ENDPOINTS.Pilgrim, {
      page: 1,
      take: 20000,
      groupId: filterModel.groupId,
    }).fetchAll();

    setdataEXcel(res.data.data);
  };
  const fetchGroups = () => {
    setstate((prev) => {
      return { ...prev, loading: true };
    });

    APi.createAPIEndpoint(APi.ENDPOINTS.Group + "/getAll")
      .fetchAll()
      .then((res) => {
        setgroups(res.data);
      })
      .catch((e) => {});
  };
  const save = () => {
    let msg = "";
    if (msg) setError(msg);
    else {
      setstate((prev) => {
        return { ...prev, loading: true };
      });
      if (model.id) {
        APi.createAPIEndpoint(APi.ENDPOINTS.Pilgrim)
          .update(model.id, model)
          .then((res) => {
            fetch();
            setstate((prev) => {
              return { ...prev, open: false, loading: false };
            });
            reset();
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "Élément a été bien modifié !",
              showConfirmButton: false,
              timer: 1500,
            });
          })
          .catch((e) => {
            setError(e.Message);
            setstate((prev) => {
              return { ...prev, loading: false };
            });
          });
      } else {
        APi.createAPIEndpoint(APi.ENDPOINTS.Pilgrim)
          .create(model)
          .then((res) => {
            fetch();
            reset();
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "Element a été bien ajouté !",
              showConfirmButton: false,
              timer: 1500,
            });
            setstate((prev) => {
              return { ...prev, open: false, loading: false };
            });
            setshowCommonInfos(false);
          })
          .catch((e) => {
            setError(e.Message);
            setstate((prev) => {
              return { ...prev, loading: false };
            });
          });
      }
    }
  };
  const send_recursive = (i, els) => {
    APi.createAPIEndpoint(ENDPOINTS.Pilgrim)
      .create(els[i])
      .then((res) => {
        if (i < els.length - 1) {
          send_recursive(i + 1, els);
          if (!(i % 10)) fetch();
        } else {
          setloadingFile(false);
          setshowCommonInfos(false);
        }
      })
      .catch((e) => {
        console.log(e);
        console.log("index: ", i);
        if (i < els.length - 1) {
          send_recursive(i + 1, els);
        } else {
          setloadingFile(false);
          setshowCommonInfos(false);
        }
      });
  };
  const send_recursive2 = (i, els) => {
    APi.createAPIEndpoint(ENDPOINTS.Pilgrim + "/Acceptation")
      .update2(els[i])
      .then((res) => {
        if (i < els.length - 1) {
          send_recursive2(i + 1, els);
          if (!(i % 10)) fetch();
        }
      })
      .catch((e) => {
        console.log(e);
        console.log("index: ", i);
        if (i < els.length - 1) {
          send_recursive2(i + 1, els);
        }
      });
  };
  const readfile3 = ($event) => {
    const files = $event.target.files;
    setloadingPassport(true);
    const file = files[0];
    setpassportFile(file);
    const formData = new FormData();

    // Update the formData object
    formData.append("File", file, file.name);

    axios
      .post("https://scan.kounouz.travel/api/Scan/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setmodel((prev) => {
          let m = new PilgrimModel();
          m.birthDate = res.data.birthDate;
          m.passportNumber = res.data.passportNumber;
          m.firsNameAr = res.data.arfirstName;
          m.mrz = res.data.mrz;
          m.lastName = res.data.lastName;
          m.firsName = res.data.firstName;
          m.gender = res.data.gender == 1 ? "M" : "F";
          m.pilgrimPicture = res.data.pilgrimPicture;

          try {
            let parsedMrz = mrzparser.parse(res.data.mrz);
            m.cin = parsedMrz.personalNumber.split(" ")[0];
            console.log(parsedMrz);
            //let parsedMrzExpiry = mrzparser.parse( res.data.mrz).expiry;

            let formatted = new Date(res.data.passportExpiryDate);
            // console.log(formatted);
            formatted.setFullYear(formatted.getFullYear() - 5);

            m.passportDeliveryDate = formatted;
            console.log(moment(m.passportDeliveryDate));
            m.passportDeliveryDate = moment(m.passportDeliveryDate)
              .add(1, "days")
              .format("YYYY-MM-DD");
            // .split("-")
            // .reverse()
            // .join("-");
            console.log(m.passportDeliveryDate);
          } catch {}
          return m;
        });
        setloadingPassport(false);
      })
      .catch((e) => setloadingPassport(false));
  };
  const readfile2 = ($event) => {
    const files = $event.target.files;

    if (files.length) {
      const file = files[0];
      setloadingFile(true);
      const reader = new FileReader();
      reader.onload = (event) => {
        const wb = read(event.target.result, {
          cellDates: true,
          dateNF: "YYYY-MM-DD",
          type: "array",
        });
        const sheets = wb.SheetNames;
        if (sheets.length) {
          let l = [];
          console.log(wb.Sheets[sheets[0]]);
          let rows = utils.sheet_to_json(wb.Sheets[sheets[0]], {
            blankrows: false,
            raw: true,
            /** Default value for null/undefined values */
            /** if true, skip hidden rows and columns */
            //skipHidden: false,
          });
          console.log(rows);
          let elems = rows.map((r, i) => {
            let _t = new PilgrimModel();
            console.log(r);
            let row = Object.entries(r);
            row = row.map((_r) => _r[1]);
            if (CommonModel.commonEmail) {
              _t.email = CommonModel.commonEmail;
            }
            if (CommonModel.phoneNumber) {
              _t.phoneNumber = CommonModel.commonPhone;
            }
            _t.groupId = CommonModel.id;
            if (row.length == 13 || row.length == 11) {
              _t.firsNameAr = row[3];

              _t.passportNumber = row[4];
              _t.documentConfirmation = row[1];

              _t.firsName = row[2];

              return _t;
            }
            _t.status = row[0];
            _t.cin = row[1] ? row[1].toString() : "";
            _t.firsNameAr = row[2];
            _t.lastNameAr = row[3];
            _t.fatherName = row[4];
            _t.grandfatherName = row[5];
            _t.motherFirstName = row[6];
            _t.motherLastName = row[7];
            _t.phoneNumber = row[8] ? row[8].toString() : "";
            _t.documentNumber = row[9];
            _t.governate = row[10];
            _t.city = row[11];
            _t.isPaid = row[12].includes("أتم") ? true : false;
            _t.confirmationDate = row[13];
            _t.sequenceNumber = row[14] ? row[14].toString() : "";
            _t.passportNumber = row[15];
            // _t.documentConfirmation = row[16]; on va mettre ici
            _t.flightNumber = row[17];
            _t.firsName = row[18];
            _t.lastName = row[19];
            _t.birthDate = row[20];
            _t.gender = row[21];
            _t.mrz = row[22];
            try {
              let parsedMrzExpiry = mrzparser.parse(row[22]).expiry;
              let formatted = new Date(
                parsedMrzExpiry.year +
                  "-" +
                  parsedMrzExpiry.month +
                  "-" +
                  parsedMrzExpiry.day
              );
              console.log(formatted);
              formatted.setFullYear(formatted.getFullYear() - 5);

              _t.passportDeliveryDate = formatted;
              console.log(moment(_t.passportDeliveryDate));
              _t.passportDeliveryDate = moment(_t.passportDeliveryDate)
                .add(1, "days")
                .format("DD-MM-YYYY");
              console.log(_t.passportDeliveryDate);
            } catch {}

            //  _t.passDE=  mrzparser.parse(row[22]).expiry
            return _t;

            // }
          });
          console.log(elems);
          send_recursive(1, elems);
          // setdata();
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };
  const readfile4 = ($event) => {
    const files = $event.target.files;

    if (files.length) {
      const file = files[0];
      setloadingFile(true);
      const reader = new FileReader();
      reader.onload = (event) => {
        const wb = read(event.target.result, {
          cellDates: true,
          dateNF: "YYYY-MM-DD",
          type: "array",
        });
        const sheets = wb.SheetNames;
        if (sheets.length) {
          let l = [];
          console.log(wb.Sheets[sheets[0]]);
          let rows = utils.sheet_to_json(wb.Sheets[sheets[0]], {
            blankrows: false,
            raw: true,
            /** Default value for null/undefined values */
            /** if true, skip hidden rows and columns */
            //skipHidden: false,
          });
          console.log(rows);
          let elems = rows
            .map((el) => Object.entries(el).map((_el) => _el[1]))
            // .filter((____el) => ____el.length == 13)
            .map((r, i) => {
              let t = { documentConfirmation: r[1], passportNumber: r[4] };

              return t;

              // }
            });

          console.log(elems);
          send_recursive2(0, elems);
          // setdata();
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };
  const deleteAction = (id) => {
    APi.createAPIEndpoint(APi.ENDPOINTS.Pilgrim)
      .delete(id)
      .then((res) => {
        fetch();
        Swal.fire("Supprimé !", "", "success");
      })
      .catch((e) => setError(e.Message));
  };
  const getBYId = (id) => {
    setError("");

    setmodel(data.find((el) => el.id == id));
  };
  // LIFE CYCLES
  useEffect(() => {
    fetch();
  }, [filterModel.page, filterModel.take]);
  return (
    <div>
      <Filter search={() => fetch()}>
        <Responsive m={6} l={6} xl={6} className="p-10">
          <label>Recherche :</label>

          <Input
            placeholder="recherche"
            onChange={(q) => {
              setfilterModel((prev) => {
                return { ...prev, q };
              });
            }}
          />
        </Responsive>
        <Responsive m={6} l={6} xl={6} className="p-10">
          <label>Groupe :</label>
          <SelectPicker
            searchable={false}
            data={[{ label: "Tout", value: 0 }].concat(
              groups.map((el) => ({ value: el.id, label: el.name }))
            )}
            block
            noSearch
            value={filterModel.groupId}
            onSelect={(groupId) => {
              setfilterModel((prev) => {
                return { ...prev, groupId };
              });
            }}
          />
        </Responsive>
      </Filter>
      <ExportAdd
        handleExport={fetchForExcel}
        ActionOnClose={() => setmodel(new PilgrimModel())}
        title={model.id ? "Modifier Pelerin " : "Ajout Pelerin"}
        additionalBtn={
          <>
            <input
              id="hidden"
              style={{
                visibility: "hidden",
                width: "0",
                height: 0,
                overflow: "hidden",
              }}
              type="file"
              onChange={readfile2}
              accept=".xlsx,.xls"
            />
            <input
              id="hidden3"
              style={{
                visibility: "hidden",
                width: "0",
                height: 0,
                overflow: "hidden",
              }}
              type="file"
              onChange={readfile3}
              accept=".jpg,.jpeg,.png"
            />
            <div style={{ textAlign: "right" }}>
              <IconButton
                icon={<Image />}
                onClick={() => {
                  const el = document.querySelector("input#hidden3");
                  console.log(el);
                  el.click();
                }}
                color="green"
              >
                Passport
              </IconButton>
              <button
                onClick={() => {
                  setshowCommonInfos(true);
                }}
                style={{
                  padding: "7px 10px",
                  background: "#330033",
                  color: "#fff",
                  fontSize: "14px",
                  margin: "0 5px",
                  border: "0 none ",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
              >
                Choisir fichier{" "}
              </button>
            </div>
          </>
        }
        excelData={multiDataSet(dataEXcel)}
        nameExcel="pelerins"
        size="md"
        save={save}
        AddComponent={
          <AddEdit
            error={error}
            model={model}
            groups={groups}
            _setmodel={setmodel}
          />
        }
      />{" "}
      <input
        id="hidden4"
        style={{
          visibility: "hidden",
          width: "0",
          height: 0,
          overflow: "hidden",
        }}
        type="file"
        onChange={readfile4}
        accept=".xlsx,.xls"
      />
      <div
        style={{
          padding: "10px",
          margin: "10px 0",
          background: "#fff",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <Peoples /> Nbr Total:
          <strong style={{ color: "green", margin: "0 15px" }}>
            {totalCount}
          </strong>
        </div>
        <IconButton
          onClick={() => {
            document.querySelector("#hidden4").click();
          }}
          icon={<PeoplesUploaded />}
        >
          Importer Numeros
        </IconButton>
      </div>
      <Grid
        editAction={(id) => {
          getBYId(id);

          setstate((prev) => {
            return { ...prev, open: true };
          });
        }}
        deleteAction={deleteAction}
        actionKey="id"
        noAdvancedActions // for custom advanced actions
        columns={columns}
        rows={data}
      />
      <div style={{ padding: 20, background: "#fff" }}>
        <ReactPaginate
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          marginPagesDisplayed={2}
          containerClassName="pagination"
          activeClassName="active"
          nextLabel=">"
          onPageChange={({ selected }) => {
            window.scrollTo(0, 0);
            setfilterModel((prev) => {
              return { ...prev, page: selected + 1 };
            });
          }}
          pageRangeDisplayed={6}
          pageCount={totalCount ? Math.ceil(totalCount / filterModel.take) : 1}
          previousLabel="<"
          renderOnZeroPageCount={null}
        />

        <div>
          Total : <span>{totalCount}</span>
        </div>
      </div>
      <Modal
        size={"md"}
        overflow={false}
        style={{
          maxHeight: "calc(100vh - 50px)",
          overflow: "auto",
          maxWidth: "100vw",
        }}
        show={showCommonInfos}
        onHide={() => {
          setshowCommonInfos(false);
        }}
      >
        <Modal.Header>
          <Modal.Title>Infos Communs</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              maxHeight: "calc(100vh - 240px)",
              overflow: "auto",
              maxWidth: "100vw",
            }}
          >
            <label>Groupe</label>
            <SelectPicker
              block
              value={CommonModel.id}
              onChange={(id) => {
                setmCommonModel((prev) => groups.find((el) => el.id == id));
                console.log(groups.find((el) => el.id == id));
              }}
              data={groups.map((el) => ({ value: el.id, label: el.name }))}
            ></SelectPicker>

            <div style={{ padding: "10px", textAlign: "center" }}>
              <IconButton
                color="green"
                disabled={!CommonModel.id}
                icon={<FileUpload />}
                onClick={() => {
                  const el = document.querySelector("input#hidden");
                  console.log(el);
                  el.click();
                }}
              >
                Choisir Fichier
              </IconButton>
              <br></br>
              <br></br>
              {loadingFile && <img src="/svg-loaders/oval.svg"></img>}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setshowCommonInfos(false);
            }}
            appearance="subtle"
          >
            Annuler
          </Button>
        </Modal.Footer>
      </Modal>
      <PassportReader
        save={save}
        loading={loadingPassport}
        close={() => {
          setpassportFile(null);
          setmodel(new PilgrimModel());
        }}
        setmodel={setmodel}
        pilgrim={model}
        file={passportFile}
        groups={groups}
      />
    </div>
  );
}

const columns = [
  {
    value: "codeMoufa",
    value2: "errorMessage",
    name: "",
    render: (v, v2) => (
      <span
        style={{
          content: "",
          width: "5px",
          height: "50px",
          background: v ? "green" : v2 ? "red" : "yellow",
          display: "block",
        }}
      ></span>
    ),
  },

  {
    value: "status",
    value2: "sequenceNumber",
    name: "الصفة",
    render: (v, v2) => (
      <div style={{ display: "flex", alignItems: "center" }}>
        {/* <img
          style={{
            width: "50px",
            height: "50px",
            borderRadius: "50%",
            marginRight: "5px",
          }}
          alt=""
          src={"C:/PilgrimsPictures/" + v2 + ".jpg"}
        /> */}
        {v}
      </div>
    ),
  },

  {
    value: "cin",
    name: "ب.ت.و",
    render: (v) => <b>{v}</b>,
  },

  {
    value: "firsNameAr",
    value2: "firsName",
    value3: "lastNameAr",
    value4: "lastName",
    name: " المترشح",
    render: (v, v2, v3, v4) => (
      <b style={{ color: "green" }}>
        {v + " " + v3} <br></br>
        <i style={{ color: "#444" }}>{v2 + " " + v4}</i>
      </b>
    ),
  },

  {
    value: "fatherName",
    name: "إسم الأب",
    render: (v) => <i style={{ color: "#555" }}>{v}</i>,
  },
  {
    value: "grandfatherName",
    name: "إسم الجد",
    render: (v) => <i style={{ color: "#232323" }}>{v}</i>,
  },
  {
    value: "motherFirstName",
    value2: "motherLastName",
    name: "إسم ألأم",
    render: (v, v2) => <b style={{ color: "#232323" }}>{v + " " + v2}</b>,
  },

  {
    value: "documentConfirmation",
    name: "رقم طلب الخارجية",
    render: (v) => <b style={{ color: "green" }}>{v ? v : "-"}</b>,
  },
  {
    value: "governate",
    value2: "city",
    name: "الولاية-المعتمدية",
    render: (v, v2) => (
      <a>
        {v}
        <br></br>
        <i style={{ color: "#232323" }}>{v2}</i>
      </a>
    ),
  },
  {
    value: "isPaid",

    name: "عملية الدفع",
    render: (v, v2) => (
      <Tag color={!v ? "red" : "green"}>{v ? "نعم" : "لا"}</Tag>
    ),
  },
  {
    value: "confirmationDate",
    name: "تاريخ ت.المطلب",
    render: (v) => <b style={{ color: "#232323" }}>{v}</b>,
  },
  {
    value: "sequenceNumber",
    name: "رقم الوصل",
    render: (v) => <i style={{ color: "#232323" }}>{v}</i>,
  },
  {
    value: "flightNumber",
    name: "رقم الرحلة",
    render: (v) => <b style={{ color: "green" }}>{v}</b>,
  },
  // {
  //   value: "mrz",
  //   name: "mrz",
  //   render: (v) => <i style={{ color: "#232323" }}>{v}</i>,
  // },
];
const PassportReader = ({
  loading,
  pilgrim,
  setmodel,
  file,
  close,
  save,
  groups,
}) => {
  return (
    <Modal
      size={"md"}
      overflow={false}
      style={{
        maxHeight: "calc(100vh - 50px)",
        overflow: "auto",
        maxWidth: "100vw",
      }}
      onHide={close}
      show={file != null}
    >
      <ModalBody>
        <img
          style={{ width: "100%" }}
          alt="passport"
          src={file && URL.createObjectURL(file)}
        />
        <br></br>
        <br></br>
        {loading ? (
          <div style={{ textAlign: "center", padding: "10px" }}>
            {" "}
            <img src="/svg-loaders/oval.svg"></img>
          </div>
        ) : (
          <div
            style={{
              dsiplay: loading ? "none" : "",
              border: "1px dashed  #eee",
              marginTop: "10px",
              borderRadius: "8px",
              position: "relative",
            }}
          >
            <img
              style={{ width: "100px", heigth: "200px" }}
              alt="pilgrim"
              src={pilgrim.pilgrimPicture}
            ></img>
            <a
              style={{
                position: "absolute",
                top: "20px",
                left: "20px",
                color: "#fff",
                background: "#4caf50",
                padding: "5px",
                borderRadius: "5px",
              }}
              download={pilgrim.passportNumber + ".jpg"}
              href={pilgrim.pilgrimPicture}
            >
              <FileDownload />
            </a>

            <div>
              <AddEdit groups={groups} _setmodel={setmodel} model={pilgrim} />
            </div>
            <div style={{ textAlign: "rigth" }}>
              <Button color="blue" onClick={save}>
                Enregistrer
              </Button>
            </div>
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};
