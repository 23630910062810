export const multiDataSet = (data) => {
  console.log(data);

  return [
    {
      columns: [
        { title: "N°", width: { wpx: 120 } },
        { title: "CIN", width: { wpx: 80 } }, //char width
        { title: "Passport", width: { wpx: 80 } }, //char width

        { title: "المترشح", width: { wpx: 180 } }, //pixels width
        { title: "المترشح", width: { wpx: 180 } }, //pixels width
        { title: "إسم الأب", width: { wpx: 150 } },
        { title: "رقم طلب الخارجية", width: { wpx: 150 } }, //pixels width
        { title: "رقم الرحلة", width: { wpx: 150 } }, //char width
        { title: "-", width: { wpx: 100 } }, //pixels width
      ],
      data: data.map((item) => {
        let item2 = [
          {
            value: item.sequenceNumber ? item.sequenceNumber : "",
            style: {
              fill: { fgColor: { rgb: "eeeeee" } },
              font: { sz: "13", color: { rgb: "2590b5" } },
              alignment: { horizontal: "center" },
            },
          },
          {
            value: item.cin ? item.cin : "",
            style: {
              font: { sz: "14", color: { rgb: "a90e43" } },
              alignment: { horizontal: "center" },
            },
          },
          {
            value: item.passportNumber ? item.passportNumber : "",
            style: {
              font: { sz: "14", color: { rgb: "4c67c2" } },
              alignment: { horizontal: "center" },
            },
          },

          {
            value:
              (item.firsNameAr ? item.firsNameAr : "") +
              " " +
              (item.lastNameAr ? item.lastNameAr : ""),

            style: {
              fill: { fgColor: { rgb: "eeeeee" } },
              alignment: { horizontal: "center" },
            },
          },
          {
            value:
              (item.firsName ? item.firsName : "") +
              " " +
              (item.lastName ? item.lastName : ""),
            style: {
              fill: { fgColor: { rgb: "f1f1f1" } },
              alignment: { horizontal: "center" },
              font: { sz: "13", color: { rgb: "2ca587" } },
            },
          },
          {
            value: item.fatherName ? item.fatherName : "",
            style: {
              font: { sz: "13", color: { rgb: "2590b5" } },
              alignment: { horizontal: "center" },
            },
          },
          {
            value: item.documentConfirmation ? item.documentConfirmation : "",
            style: {
              fill: { fgColor: { rgb: "eeeeee" } },

              font: { sz: "13" },
              alignment: { horizontal: "center" },
            },
          },

          {
            value: item.flightNumber ? item.flightNumber : "",
            style: {
              font: { sz: "14", color: { rgb: "5a2c3e" } },
              alignment: { horizontal: "center" },
            },
          },
          {
            value: item.codeMoufa
              ? item.codeMoufa
              : item.errorMessage
              ? item.errorMessage
              : "",
            style: {
              font: {
                sz: "14",
                color: { rgb: item.codeMoufa ? "673ab7" : "f0b217" },
              },
              alignment: { horizontal: "center" },
            },
          },
        ];
        return item2;
      }),
    },
  ];
};
